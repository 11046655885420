<!--
author:        chenjie <zlf@beyondin.com>
date:          2023-03-21 16:21:36
component:     index
Copyright © YourCompanyName All rights reserved
-->
<template>
  <div>
    <div id="header" v-if="!workbenchNav" :class="{'bj': className == 'mr630'}">
      <el-row type="flex">
        <el-col :span="4">
          <div class="header-icon cup" @click="onNav({path:'/home'})">
            <img src="https://oss.yucongkeji.cn/yucong-home/img/title-icon.png" />
          </div>
        </el-col>
        <el-col :span="isOut ? 19 : 16">
          <div class="flex header-nav-list">
            <div
              v-for="(item) in list"
              :key="item.path"
              class="header-nav font14"
              @click="onNav(item)"
            >
              {{ item.name }}
            </div>
            <p :class="className" v-if="className"></p>
          </div>
        </el-col>
        <el-col :span="5">
          <el-row type="flex" style="justify-content: end" class="header-rightbut">
            <el-col :span="7"><el-button class="DCbut" type="primary" @click="jumpWorkbenchRouter">工作台</el-button></el-col>
            <el-col :span="8" v-if="isOut"><el-button class="BgDCbut" type="primary" plain @click="onLogin">请您登录/注册</el-button></el-col>
            <div class="flex y-center user-info c-center" v-else>
              <img :src="userForm?.avatar" />
              <el-dropdown>
                <div class="flex y-center c-center cup">
                  <div class="font14 fff user-info-name">{{ userForm?.nickName }}</div>
                  <i class="el-icon-arrow-down el-icon--right" style="color:#fff"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item, index) in menuList" :key="index" @click.native="onOperation(item.title,item.url)">
                    <span >
                      <i :class="`iconfont ${item.icon}`"></i>
                      {{ item.title }}
                    </span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <!-- 工作台导航 -->
    <div id="workbenchHeader" v-else>
      <el-row type="flex">
        <el-col :span="12" style="display: flex;" class="flex-left">
          <div class="header-icon-work cup" @click="onNav({path:'/home'})">
            <img src="https://oss.yucongkeji.cn/yucong-home/img/logo.png" />
          </div>
          <div style="display: flex;align-items: center;">
            <el-button @click="jumpWorkbenchRouter"><em class="el-icon-monitor"></em>工作台</el-button>
          </div>
        </el-col>
        <el-col :span="12"  style="display: flex;justify-content: flex-end;" class="flex-right">
          <el-button type="primary"  @click="jumpServices">
            <em class="iconfont icon-ServiceAndSupport"></em>
            售后与支持
          </el-button>
          <el-button type="primary" plain @click="jumpHome">
            <em class="iconfont icon-home"></em>
            首页
          </el-button>
          <el-button v-if="isOut" type="primary" class="BgDCbut" @click="onLogin">请您登录/注册</el-button>
            <div v-else class="flex y-center user-info c-center" style="margin: 0px 40px;">
              <img :src="userForm?.avatar" alt="头像" />
              <el-dropdown>
                <div class="flex y-center c-center cup">
                  <div class="font14 user-info-name">{{ userForm?.nickName }}</div>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item, index) in menuList" :key="index" @click.native="onOperationForWorkNav(item.title,item.url)">
                    <span >
                      <i :class="`iconfont ${item.icon}`"></i>
                      {{ item.title }}
                    </span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { out } from '@server/my';

export default {
    data() {
        return {
            list: [
                {
                    path: '/home',
                    name: '首页',
                    className: 'mr30'
                },
                {
                    path: '/productIntroduction',
                    name: '产品介绍',
                    className: 'mr150'
                },
                {
                    path: '/system',
                    name: '服务定价',
                    className: 'mr270'
                },
                // {
                //     path: '/subscription',
                //     name: '订阅升级',
                //     className: 'mr270'
                // },
                {
                    path: '/scheme',
                    name: '解决方案',
                    className: 'mr390'
                },
                {
                    path: '/services',
                    name: '售后与支持',
                    className: 'mr510'
                },
                {
                    path: '/about',
                    name: '关于我们',
                    className: 'mr630'
                }
            ], // nav
            menuList:[{
                title: '订单列表',
                icon: 'icon-OrderList',
                url: '/user-center/order'
            }, {
                title: '优惠券',
                icon: 'icon-Coupon',
                url: '/user-center/Coupon'
            }, {
                title: '用户中心',
                icon: 'icon-UserCenter',
                url: '/user-center/info'
            }, {
                title: '退出登录',
                icon: 'icon-LogOut'
            }],
            isOut: true, // 是否退出
            useInfo: {}, // 用户信息
            workbenchNav:false,//除工作台导航栏展示
            className: ''
        };
    },
    watch:{
        '$route':{
            immediate:true,
            deep:true,
            handler(to){
                this.getMuen(to.path);
                if(to.meta.title=='工作台' ||to.meta.title=='对账单'|| to.meta.title =='续费/扩容'){
                    this.workbenchNav = true;
                }else {
                    this.workbenchNav = false;
                }
            }
        },
        userForm:{
            immediate:true,
            deep:true,
            handler(val){
                this.isOut =  !val.id;
            }
        }
    },
    computed: {
        userForm () {
            return this.$store.state.user;
        }
    },
    mounted() {
        if (sessionStorage.muen) {this.getMuen(sessionStorage.muen);}
        if (localStorage.userInfo) {
            this.useInfo = JSON.parse(localStorage.userInfo);
            this.$store.commit('SET_USER', this.useInfo);
            this.isOut = false;
        }
        this.$EventBus.$on('login', (data)=>{
            this.isOut = false;
            this.useInfo = data;
            this.$EventBus.$off('login');
        });
    },
    methods: {
    // 切换
        onNav({path}) {
            this.getMuen(path);
            this.$router.push(path);
        },
        getMuen(path) {
            //  console.log(path);
            if(path == '/order'){
                this.className = 'mr270';
                return;
            }
            sessionStorage.setItem('muen', '/');
            this.className = '';
            this.list = this.list.map(item => {
                if (item.path === path) {
                    this.className = item.className;
                    sessionStorage.setItem('muen', path);
                }
                return item;
            });
        },
        // 登录
        onLogin() {
            this.getMuen('/');
            this.$router.push('/login');
        },
        // 操作
        onOperation(item,url) {
            if (item === '退出登录') {
                this.$confirm('确定退出当前登录吗？', '退出登录', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass:"BgDCbut"
                }).then(async () => {
                    await out();
                    this.isOut = true;
                    localStorage.removeItem('userInfo');
                    if (!this.list.some(({path}) => this.$route.path == path )) {
                        this.$router.push(`/login`);
                    }
                });
            }else if(url!=this.$route.path){
                this.$router.push(url);
            }
        },
        //工作台退出
        onOperationForWorkNav(item,url){
            if (item === '退出登录') {
                this.$confirm('确定退出当前登录吗？', '退出登录', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass:"BgDCbut"
                }).then(async () => {
                    await out();
                    this.isOut = true;
                    localStorage.removeItem('userInfo');
                    this.$router.push(`/login`);
                }).catch((e)=>{
                    console.log(e);
                });
            }else if(url!=this.$route.path){
                this.$router.push(url);
            }
            console.log(url,'url');
        },
        //跳转工作台
        jumpWorkbenchRouter(){
            this.$router.push(`/workbench`);
        },
        //跳转售后与支持
        jumpServices(){
            this.$router.push(`/services`);
        },
        //跳转首页
        jumpHome(){
            this.$router.push(`/home`);
        }
    }
};
</script>

<style lang="scss" scoped>
  @import './header.scss';
  .el-dropdown-menu__item:hover {
    background-color: #F5F8FE !important;
    color: #0053DC !important;
}

</style>