<!--
author:        chenjie <zlf@beyondin.com>
date:          2023-03-22 18:58:31
component:     yc-upload
Copyright © YourCompanyName All rights reserved
-->
<template>
  <div>
      <div class="flex">
        <el-upload
          class="avatar-uploader"
          action=""
          :multiple="multiple"
          :show-file-list="false"
          :http-request="uploadHttp"
          :before-upload="onBeforeFileUpload"
          v-if="this.imageUrl.length < this.max"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <template>
        <div class="flex">
          <div v-for="(item, index) in imageUrl" :key="index" class="avatarlist avatar">
            <el-image :src="item.url" class="avatar" />
            <div class="operation flex y-center x-center">
              <!-- <i class="el-icon-zoom-in"></i> -->
              <i class="el-icon-delete" @click="onremove(index)"></i>
            </div>
          </div>
        </div>
        </template>
      </div>
      <div class="c6 font14">{{ tip }}</div>
  </div>
</template>
<script>
import OSS from "ali-oss";
import { getoss } from '@server/home';
import { Decrypt } from '@utils/utils';
export default {
    name: 'yc-upload',
    props: {
        multiple: {
            type: Boolean,
            default: false // 是否多选
        },
        max: {
            type: Number,
            default: 1 // 最多几张
        },
        size: {
            type: Number,
            default: 50 // 文件大小
        },
        tip: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            imageUrl: [] // 图片
        };
    },
    methods: {
        // 用户图片上传
        async uploadHttp({ file }) {
            const fileType = file.name.split(".");

            console.log(fileType);
            const getUUid = localStorage.userInfo ? JSON.parse(localStorage.userInfo).uuid:'coupleBack';
            const fileName = `/oa/source/task/attach/yucong-home/${getUUid}/${Date.parse(
                new Date()
            )}.${file.name.split(".")[fileType.length - 1]}`; //定义唯一的文件名

            console.log(fileName,'fileName');
            const size = file.size;
            const { data } = await getoss();
            let {accessKeyId,accessKeySecret,bucket,location} = JSON.parse(Decrypt(data));

            new OSS({
                region: location,
                accessKeyId,
                accessKeySecret,
                bucket,
                secure: true
            }).put(fileName, file, {
                ContentType: "image/jpeg"
            })
                .then(async ({ res, url, name }) => {
                    if (res && res.status == 200) {
                        this.imageUrl.push({
                            name: file.name.split(".")[fileType.length - 1],
                            ossFileName: fileName,
                            url: `${url}?x-oss-process=image/resize,w_150,h_150`,
                            size
                        });
                        this.$emit('uploadParams', this.imageUrl);
                    }
                })
                .catch((err) => {
                    console.log(`阿里云OSS上传图片失败回调`, err);
                });
        },
        onBeforeFileUpload(file) {
            const isLt50M = file.size / 1024 / 1024 < this.size;
            // if (!localStorage.userInfo) {
            //   this.$message.error(`请先登录在提出您的需求`);
            //   setTimeout(() => {
            //     this.$router.push('/login')
            //   }, 2000);
            // }

            if (!isLt50M) {return this.$message.error(`上传头像图片大小不能超过 ${this.size}MB!`);}
            if (this.imageUrl.length >= this.max) {return this.$message.error(`最多只能上传${this.max}张`);}
        },
        // 删除
        onremove(index) {
            this.imageUrl.splice(index, 1);
        },
        onsuccess() {
            this.imageUrl = [];
        }
    }
};
</script>
<style lang="scss">
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .avatarlist {
    position: relative;
    margin: 0 0 12px 12px;
    &:hover {
      .operation {
        display: flex;
      }
    }
  }
  .operation {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgb(29 17 17 / 45%);
    display: none;
    >i {
      color: #fff;
      font-size: 24px;
      // margin-left: 16px;
    }
  }
</style>