/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-21 14:41:33
 * Copyright © YourCompanyName All rights reserved
 */
import axios from 'axios';
import { BASEURL } from '../config/const';
import { Message } from "element-ui";
import router from '@/router';
import store from '@/store/index';
let debouncePath = ['/official/user/edit'];
let IsDebouncePath = true;


const fetch = (url = '', parmas) => {

    // 接口域名
    const baseURL = BASEURL();
    const headers = {};

    if (localStorage.getItem('userInfo')) {headers['Authorization'] = JSON.parse(localStorage.getItem('userInfo')).token;}
    let item = debouncePath.includes(url) ? 1000 : 0;

    return new Promise((resolve, reject) => {
        let fn = () => {
            axios({
                url,
                baseURL,
                headers,
                ...parmas
            }).then((res) => {
                if (res.status === 200) {
                    return res.data;
                }
                reject(res);

            }).then((res) => {
                if (res.code === 200) {
                    resolve(res);
                } else if (res.code === 10001 || res.code === 10002 || res.code === 10003) {
                    store.commit('SET_USER', {});
                    localStorage.removeItem('userInfo');
                    router.push('/Login');
                } else {
                    Message.error(res.msg);
                }
            }).catch((err) => {
                reject(err);
            });
        };

        console.log(IsDebouncePath);
        if (item && IsDebouncePath) {
            IsDebouncePath = false;
            setTimeout(() => {
                fn();
                IsDebouncePath = true;
            }, item);

        } else if (!item) {
            fn();
        }
    });



};


// export default debounce(1000,fetch)
export default fetch;