/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-21 14:38:40
 * Copyright © YourCompanyName All rights reserved
 */
const MyRouter = [{
    path: '/login',
    name: 'login',
    component: () =>
        import ('../views/My/Login/index'),
    meta: {
        title: '登录'
    }
},
{
    path: '/user-center/order-detail/:id',
    name: 'order-detail',
    component: () =>
        import ('../views/My/UserCenter/OrderDetail/index')
},
{
    path: '/agreement/:type',
    name: 'agreement',
    component: () =>
        import ('../views/My/Agreement/index'),
    meta: {
        title: '协议'
    }
},
{
    path: '/user-center',
    name: 'user-center',
    redirect: '/user-center/info',
    component: () =>
        import ('../views/My/UserCenter/index'),
    meta: {
        title: '用户中心'
    },
    children: [{
        path: '/user-center/info',
        name: 'info',
        component: () =>
            import ('../views/My/UserCenter/UserInfo/index'),
        meta: {
            title: '基本信息'
        }
    },
    {
        path: '/user-center/order',
        name: 'order',
        component: () =>
            import ('../views/My/UserCenter/OrderList/index'),
        meta: {
            title: '订单列表'
        }
    },
    {
        path: '/user-center/Coupon',
        name: 'user-center',
        component: () =>
            import ('../views/My/UserCenter/Coupon/index'),
        meta: {
            title: '优惠券'
        }
    },
    {
        path: '/user-center/order-detail',
        name: 'order-detail',
        component: () =>
            import ('../views/My/UserCenter/OrderDetail/index'),
        meta: {
            title: '订单详情'
        }
    },
    {
        path: '/user-center/CommercialTenant',
        name: 'user-commercialtenant',
        component: () =>
            import ('../views/My/UserCenter/CommercialTenant/index'),
        meta: {
            title: '绑定企业'
        }
    },
    {
        path: '/user-center/InvoiceTitle',
        name: 'user-invoiceTitle',
        component: () =>
            import ('../views/My/UserCenter/InvoiceTitle/index'),
        meta: {
            title: '发票抬头'
        }
    },
    {
        path: '/user-center/applyForBilling',
        name: 'user-applyForBilling',
        component: () =>
            import ('../views/My/UserCenter/applyForBilling/index'),
        meta: {
            title: '申请开票'
        }
    }, {
        path: '/user-center/applyForBillingSuccess',
        name: 'user-applyForBillingSuccess',
        component: () =>
            import ('../views/My/UserCenter/applyForBillingSuccess/index'),
        meta: {
            title: '开票成功'
        }

    }, {
        path: '/user-center/applyForBillingDetail',
        name: 'user-applyForBillingDetail',
        component: () =>
            import ('../views/My/UserCenter/applyForBillingDetail/index'),
        meta: {
            title: '开票详情'
        }

    }
    ]
}
];

export default MyRouter;