const WorkbenchRouter = [{
    path: '/',
    redirect: '/workbench'
},
{
    path: '/workbench',
    name: 'workbench',
    component: () =>
        import ('../views/Workbench/index'),
    meta: {
        title: '工作台'
    }
}
];

export default WorkbenchRouter;