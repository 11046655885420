/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-23 11:17:35
 * Copyright © YourCompanyName All rights reserved
 */

// 接口域名
const BASEURL = () => {
    const { VUE_APP_UMI_ENV } = process.env;
    // 开发

    if(VUE_APP_UMI_ENV === 'test') {
        return 'https://api.yucongkeji.com//yucong-home-dev';
    } else if (VUE_APP_UMI_ENV === 'pro') {
        return 'https://api.yucongkeji.com//yucong-home-prod';
    } else if (VUE_APP_UMI_ENV === 'dev') {
        return 'http://192.168.1.6:8508/yucong-home-dev';
    }
    return '';

};

export {
    BASEURL
};