/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-21 14:45:46
 * Copyright © YourCompanyName All rights reserved
 */

import fetch from './api';

// 获取系统名称与规格
const classifyTable = async() => {
    return await fetch('/doc/classify/table', {
        method: 'get'
    });
};
const documentTable = async(params) => {
    return await fetch('/document/table', {
        method: 'get',
        params
    });
};
const documentInfo = async(params) => {
    return await fetch('/document/select/info', {
        method: 'get',
        params
    });
};

// 获取常见问题
const getProblemList = async() => {
    return await fetch('/document/select/common/problem', {
        method: 'get'
    });
};
// 获取页脚问题
const getFooterList = async() => {
    return await fetch('/document/select/footer/display', {
        method: 'get'
    });
};


export {
    getProblemList,
    classifyTable,
    documentTable,
    documentInfo,
    getFooterList
};