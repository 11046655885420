/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-23 15:24:53
 * Copyright © YourCompanyName All rights reserved
 */
const mutations = {
    SET_USER(state,payload){
        state.user = payload;
    }
};

export default mutations;