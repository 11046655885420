<template>
    <ul class="connect">
        <li @mouseover="isOpacity = true" @mouseout="isOpacity = false">
            <div class="iconbox cup">
                <em class="iconfont icon-QRCode"></em>
                <span>微信咨询</span>
            </div>
            <div>
              <!-- <img src="https://oss.yucongkeji.cn/yucong-home/img/kefucode.png" alt=""> -->
              <img src="https://oss.yucongkeji.cn/yucong-home/img/service.png" alt="" :class="{'opacity1': isOpacity}">
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    data(){
        return{
            isOpacity: false
        };
    }
};
</script>

<style lang="scss" scoped>
.connect{
    position: fixed;
    right: 0;
    top: 70%;
    z-index: 100;
    li{
        padding: 12px;
        position: relative;
        list-style: none;
        background-color: #0053DC;
        color: #fff;
        border-radius: 4px 0px 0px 4px;
        em{
            font-size: 32px;
            color: #fff;
        }
        .iconbox{
            display: flex;
            justify-content: space-around;
            flex-flow: column;
            height: 100%;
            align-items: center;
            span{
                font-size: 14px;
            }
        }
        img{
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.12);
            border-radius: 4px;
            opacity: 0;
            top: -200px;
            right: 85px;
            position: absolute;
            width: 0;
            height: auto;
            transition: all .3s;
        }
        .opacity1{
            opacity: 1;
            width: 300px;
         }
    }
}

</style>