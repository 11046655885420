/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-22 17:10:48
 * Copyright © YourCompanyName All rights reserved
 */
import CryptoJS from "crypto-js";
import { Message } from "element-ui";
// 24小时倒计时
const timestampToTime = (time, timer) => {
    if(!time) {return [];}
    const starttime = Date.parse(new Date());
    const endtime = Date.parse(new Date(time));
    let res = endtime - starttime;
    let hours = parseInt(res / (1000 * 60 * 60 ));
    let minutes = parseInt(res % (1000 * 60 * 60) / (1000 * 60));
    let seconds = res % (1000 * 60) / 1000;

    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    var miao = Number(seconds).toFixed(0);

    if(miao < 10){
        miao = "0" + miao;
    }
    if(hours <=0 && minutes <= 0 && miao <= 0) {
        clearTimeout(timer);
    }
    return [hours, minutes, miao];
};
//时间戳转换时间

function VdateFormat(value, type = 'mm') {
    if (!value) {return '';}
    let thisMoment = new Date();
    const dt = new Date(value);
    const thisYear = thisMoment.getFullYear();
    // 年月日 月日不足两位补 0
    const y = dt.getFullYear();
    const m = String(dt.getMonth() + 1).padStart(2, '0');
    const d = String(dt.getDate()).padStart(2, '0');

    // 时分秒
    const hh = String(dt.getHours()).padStart(2, '0');
    const mm = String(dt.getMinutes()).padStart(2, '0');
    const ss = String(dt.getSeconds()).padStart(2, '0');
    let result = '';

    if (type == 'day') {
        result = `${y}-${m}-${d}`;
    } else if (type == 'mm') {
        result = `${y}-${m}-${d} ${hh}:${mm}`;
    } else if (type == 'ss') {
        result = `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
    } else if (type == 'fullTime') {
        return `${y}-${m}-${d} ${hh}:${mm}`;
    } else if (type == 'fullday') {
        return result = `${y}-${m}-${d}`;
    } else if (type == 'noYear') {
        if (thisYear == y) {
            result = `${m}-${d} ${hh}:${mm}`;
        } else {
            result = `${y}-${m}-${d} ${hh}:${mm}`;
        }
    } else if (type == 'timer') {
        return `${hh}:${mm}:${ss}`;
    } else if (type == 'Hours') {
        return `${hh}:${mm}`;
    } else if(type == 'm'){
        return  result = `${m}/${d}  ${hh}:${mm}`;

    }
    return result;
}

// 加密
const Encrypt = (word, keyStr) => {
    keyStr = keyStr ? keyStr : "abso789lj32fai12";
    let key = CryptoJS.enc.Utf8.parse(keyStr);
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
};
// 解密
const Decrypt = (word, keyStr) => {
    keyStr = keyStr ? keyStr : "abso789lj32fai12";
    var key = CryptoJS.enc.Utf8.parse(keyStr);
    var decrypt = CryptoJS.AES.decrypt(word, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
};

function copyText(text) {
    var input = document.createElement('textarea');

    input.value = text;
    document.body.appendChild(input);
    input.select();
    if (document.execCommand('copy')) {
        document.execCommand('copy');
        Message.success('已复制内容到剪切板');
    }
    document.body.removeChild(input);
}

export {
    timestampToTime,
    VdateFormat,
    Encrypt,
    Decrypt,
    copyText
};