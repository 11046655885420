/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-21 14:45:14
 * Copyright © YourCompanyName All rights reserved
 */
const SchemeRouter = [{
    path: '/scheme',
    name: 'scheme',
    component: () =>
        import ('../views/Scheme/index.vue'),
    meta: {
        title: '解决方案'
    }
} ];

export default SchemeRouter;