/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-21 14:44:47
 * Copyright © YourCompanyName All rights reserved
 */

import fetch from './api';

// 退出登录
const out = async () => {
    return await fetch('/out', {
        method: 'get'
    });
};
// 发送验证码
const smsSend = async (data) => {
    return await fetch('/sms/send', {
        method: 'post',
        data
    });
};
// 登录
const login = async (data) => {
    return await fetch('/login', {
        method: 'post',
        data
    });
};
// 注册效验
const registerCheck = async (params) => {
    return await fetch('/register/check', {
        method: 'get',
        params
    });
};
// 注册
const register = async (data) => {
    return await fetch('/register', {
        method: 'post',
        data
    });
};
// 忘记密码验证码效验
const passwordCheck = async (params) => {
    return await fetch('/forgot/password/check', {
        method: 'get',
        params
    });
};

// 修改密码
const passwords = async (data) => {
    return await fetch('/user/edit/password', {
        method: 'post',
        data
    });
};

//获取用户信息
const userInfo = async (params) => {
    return await fetch('/official/user/info', {
        method: 'get',
        params
    });
};
//修改用户信息
const Setuser = async (data) => {
    return await fetch('/official/user/edit', {
        method: 'post',
        data
    });
};

//获取订单列表
const orderTable = async (params) => {
    return await fetch('/order/table', {
        method: 'get',
        params
    });
};
const refreshPics = async (params) => {
    return await fetch('/slider/pics', {
        method: 'get',
        params
    });
};



export {
    out,
    login,
    smsSend,
    registerCheck,
    register,
    passwordCheck,
    passwords,
    userInfo,
    Setuser,
    orderTable,
    refreshPics
};