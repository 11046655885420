/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-21 14:44:35
 * Copyright © YourCompanyName All rights reserved
 */
const AboutRouter = [{
    path: '/about',
    name: 'about',
    component: () =>
        import ('../views/About/index.vue'),
    meta: {
        title: '关于我们'
    }
} ];

export default AboutRouter;