const StatementRouter = [{
    path: '/',
    redirect: '/statement'
},
{
    path: '/statement',
    name: 'statement',
    component: () =>
        import ('../views/Statement/index'),
    meta: {
        title: '对账单'
    },
    children: [{
        path: '/statement/renew',
        name: 'renew',
        component: () =>
            import ('../views/Statement/ReNew/index'),
        meta: {
            title: '续费/扩容'
        }
    },
    {
        path: '/statement/order',
        name: 'order',
        component: () =>
            import ('../views/Statement/Order/index.vue'),
        meta: {
            title: '对账单'
        }
    }
    ]
}
];

export default StatementRouter;