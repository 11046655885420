import Vue from 'vue';
import VueRouter from 'vue-router';

import HomeRouter from './home'; //logo
import ServicesRouter from './services'; //售后与支持
import SchemeRouter from './scheme'; // 解决方案
import SubscriptionRouter from './subscription'; //订阅升级
import SystemRouter from './system'; //系统定价
import AboutRouter from './about'; //关于我们
import MyRouter from './my'; //我的
import WorkbenchRouter from './workbench'; //工作台
import StatementRouter from './statement';
import ProductIntroduction from './productIntroduction';


const routes = [
    ...HomeRouter,
    ...ServicesRouter,
    ...SchemeRouter,
    ...SubscriptionRouter,
    ...SystemRouter,
    ...AboutRouter,
    ...MyRouter,
    ...WorkbenchRouter,
    ...StatementRouter,
    ...ProductIntroduction
];


const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    const { meta: { title = '易企伴工' } } = to;

    document.title = '易企伴工|'+title;
    next();
    window.scrollTo(0, 0);
});



router.onError((error) => {
    console.log(123123123123);
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;

    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
});

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

export default router;