/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-21 14:38:40
 * Copyright © YourCompanyName All rights reserved
 */
const SubscriptionRouter = [{
    path: '/subscription',
    name: 'subscription',
    component: () =>
        import ('../views/Subscribe/index'),
    meta: {
        title: '订阅升级'
    }
} ];

export default SubscriptionRouter;