
const productIntroduction = [{
    path: '/productIntroduction',
    name: 'productIntroduction',
    component: () =>
        import ('../views/ProductIntroduction/index.vue'),
    meta: {
        title: '产品介绍'
    }
} ];

export default productIntroduction;