/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-21 14:38:40
 * Copyright © YourCompanyName All rights reserved
 */
const HomeRouter = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/Home/index'),
        meta: {
            title: '首页'
        }
    }
];

export default HomeRouter;