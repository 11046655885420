/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-23 15:23:07
 * Copyright © YourCompanyName All rights reserved
 */
const state = {
    fixPrice: {}, // 获取系统名称与规格
    user:{}
};

export default state;