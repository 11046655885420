<template>
    <span ref="numberGrow" :data-time="time" class="number-grow">0</span>
</template>
<script>
export default {
  name: 'NumberCounter',
  props: {
      time: {
          type: Number,
          default: 1
      },
      value: {
          type: Number,
          default: 0
      }
  },
  data() {
      return {

      }
  },
  mounted() {
      window.addEventListener("scroll", this.scrolling)
  },
  methods: {
      scrolling() {
        if (!this.$refs.numberGrow) return
        let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
        if (this.$refs.numberGrow.clientTop / 2 < scrollTop) {
          this.numberGrow(this.$refs.numberGrow)
          setTimeout(() => {
            window.removeEventListener("scroll", this.scrolling)
          }, 2000);
        }
      },
      numberGrow(ele) {
          let step = parseInt((this.value) / (this.time * 100))
          let current = 0
          let start = 0
          let t = setInterval(() => {
              start += step + 1
              if (start >= this.value) {
                  clearInterval(t)
                  start = this.value
                  t = null
              }
              if (current === start) {
                  return
              }
              current = start
              ele.innerHTML = current.toString().replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, '$1')
          }, 10)
      }
  },
}
</script>