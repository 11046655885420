<template>
  <div id="app">
     <img class="logoimg" src="https://oss.yucongkeji.cn/17office/img/sousuo-cut-logo.png" alt="">
    <Header v-if="isShowHeader"/>
    <div id="yc-content">
      <router-view />
    </div>
    <Footer v-if="isShow" />
    <Bar></Bar>
    <el-backtop :bottom="20" :right="10">
      <i class="el-icon-upload2"></i>
    </el-backtop>
  </div>
</template>


<script>
import Bar from '@/components/bar/index.vue';
import Footer from '@components/Footer/footer.vue';
import Header from '@components/Header/index';

export default {
    components: {
        Footer,
        Header,
        Bar
    },
    data() {
        return {
            isShow: false,
            isShowHeader:true
        };
    },
    watch:{
        $route:{
            immediate:true,
            handler({path,name}){
                if(name == 'agreement'){
                    this.isShowHeader = false;
                    this.isShow = true;
                }else  if (path == '/about' || path == '/scheme' || path == '/productIntroduction') {
                    this.isShow = false;
                    this.isShowHeader = true;
                } else {
                    this.isShow = true;
                    this.isShowHeader = true;
                }

                if (process.env.NODE_ENV != "development") {
                    if (localStorage.version && localStorage.version != process.env.VUE_APP_UMI_VERSION) {
                        localStorage.version = process.env.VUE_APP_UMI_VERSION;
                        setTimeout(() => {
                            location.reload(true);
                        }, 500);
                    }
                    if (!localStorage.version) {localStorage.version = process.env.VUE_APP_UMI_VERSION;}
                }
            }
        }
    }
};
</script>

<style lang="scss">
  @import './app.scss';

</style>
