import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import '@assets/iconFont/iconfont.css';
// 组件
import VueCropper from "vue-cropper";
import ycUpload from '@components/yc-upload.vue';
import ycIdentify from '@components/yc-identify.vue';
import ycLoading from '@components/yc-loading.vue';
import ycNumberCounter from '@components/yu-numberCounter.vue';
import { Boot } from '@wangeditor/editor';
import markdownModule from '@wangeditor/plugin-md';
import VueAnimateOnScroll from 'vue-animate-onscroll';
import "swiper/css/swiper.css";
import 'animate.css';
import '@/assets/font/font.scss';

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
Vue.prototype.$EventBus = new Vue();

Vue.use(ElementUI);
Vue.use(VueCropper);
Vue.use(VueAnimateOnScroll);
Vue.component('yc-upload', ycUpload);
Vue.component('yu-identify', ycIdentify);
Vue.component('yc-loading', ycLoading);
Vue.component('yu-numberCounter', ycNumberCounter);

Boot.registerModule(markdownModule);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');