/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-21 14:45:58
 * Copyright © YourCompanyName All rights reserved
 */

const SystemRouter = [
    {
        path: '/system',
        name: 'system',
        component: () => import('../views/System/index'),
        meta: {
            title: '服务定价'
        }
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('../views/System/Order/index'),
        meta: {
            title: '下单'
        }
    },
    {
        path: '/pay',
        name: 'pay',
        component: () => import('../views/System/Pay/index'),
        meta: {
            title: '支付'
        }
    },
    {
        path: '/pay-success',
        name: 'pay-success',
        component: () => import('../views/System/PaySuccess/index'),
        meta: {
            title: '支付成功'
        }
    }
];

export default SystemRouter;