/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-21 14:38:40
 * Copyright © YourCompanyName All rights reserved
 */
const PrivateRouter = [{
    path: '/services',
    name: 'services',
    component: () =>
        import ('../views/Services/index.vue'),
    meta: {
        title: '售后与支持'
    }
}, {
    path: '/services/help',
    name: 'help',
    component: () =>
        import ('../views/Services/HelpCenter/index.vue'),
    meta: {
        title: '文档详情'
    }
},
{
    path: '/services/helpClassify',
    name: 'helpClassify',
    component: () =>
        import ('../views/Services/HelpCenter/helpClassify/index.vue'),
    meta: {
        title: '帮助中心'
    }
}


];

export default PrivateRouter;