/**
 * @author        chenjie <zlf@beyondin.com>
 * @date          2023-03-21 14:38:40
 * Copyright © YourCompanyName All rights reserved
 */
import fetch from './api';

// 首页banner
const bannerList = async () => {
    return await fetch('/official/banner/list', {
        method: 'get'
    });
};

// 获取oss配置信息
const getoss = async () => {
    return await fetch('/oss/conf/get', {
        method: 'get'
    });
};

// oss上传记录
const managerAdd = async (data) => {
    return await fetch('/oss/manager/add', {
        method: 'post',
        data
    });
};


export {
    bannerList,
    getoss,
    managerAdd
};