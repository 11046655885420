<!--
author:        chenjie <zlf@beyondin.com>
date:          2023-03-21 15:47:27
component:     footer
Copyright © YourCompanyName All rights reserved
-->
<template>
  <div id="footer" v-if="isShow">
    <el-row type="flex" justify="center" class="footer-header">
      <el-col :span="3" v-for="(item, index) in footerNav" :key="index">
        <div class="flex y-center" :class="{'x-center': index === 2}">
          <img :src="item.icon" class="footer-nav-icon" />
          <span class="font14">{{ item.name }}</span>
        </div>
      </el-col>
    </el-row>
    <div class="footer-y-nav D1D1D1" >
      <el-row type="flex" justify="center">
        <el-col :span="item.h1 === '帮助中心' ? 6 : 5" v-for="(item, index) in footerYNav" :key="index" :class="{'footer-contact': index === 3}">
          <h2 class="font16 fff">{{ item.h1 }}</h2>
          <div v-if="item.h1 != '帮助中心'" >  
            <p @click="Tofooter(item.title1)" :class="item.h1 === '联系方式' ? item.title1 == 'E-mail：official@yucongkeji.com' ?   'font14 emailFont' : 'font14' : 'font14 cup'">{{ item.title1 }}</p>
            <p @click="Tofooter(item.title2)" :class="item.h1 === '联系方式' ?  'font14 ' : 'font14 cup'">{{ item.title2 }}</p>
            <p @click="Tofooter(item.title3)" :class="item.h1 === '联系方式' ?  'font14 ' : 'font14 cup'">{{ item.title3 }}</p>
            <p @click="Tofooter(item.title4)" :class="item.h1 === '联系方式' ?  'font14 ' : 'font14 cup'">{{ item.title4 }}</p>
            <p @click="Tofooter(item.title5)" :class="item.h1 === '联系方式' ?  'font14 ' : 'font14 cup'">{{ item.title5 }}</p>
            <!-- <img :src="qrcodeUrl" v-if="item.title5" /> -->
          </div>
          <div v-else>
            <p  v-for="val,index in problemList" :key="index" @click="Tofooter(val.id,'problem')" class="font14 cup">{{ val.title }}</p> 
          </div>
        </el-col>
        <el-col :span="4" class="ml16">
          <h2 class="font16 fff">在线客服</h2>
          <div  class="flex y-center mt12">
            <div>
              <img class="qrcode" src="https://oss.yucongkeji.cn/develop/avatar/congcong-avatar.png" />
              <div style="text-align:center; width: 100%" class="font14">微信客服</div>
            </div>
            <div class=" ml12">
              <img class="qrcode" src="https://oss.yucongkeji.cn/develop/avatar/bosszhiliao.png" />
              <div style="text-align:center; width: 100%" class="font14">boss直聊</div>
            </div>
          </div>
        </el-col>
       
      </el-row>
    </div>
    <el-row type="flex" justify="center" class="footer-label">
      <el-col :span="24" class="footer-label-left">
        <span class="D1D1D1 font14">Copyright © 杭州语聪网络科技有限公司. All Rights Reserved</span>
      </el-col>
      <el-col :span="4" style="text-align:right" class="footer-label-left cup">
        <img src="https://oss.yucongkeji.cn/picture/beian.png" alt=""><span class="D1D1D1 font14" @click="openbeian">浙ICP备20022843</span>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import Footer from './footer'
  export default Footer
</script>
<style lang="scss">
  @import './footer.scss'
</style>